*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.footer {
  font-size: clamp(.8rem, 1vw, .9rem);
}

body {
  /* font-size: clamp(1em, 3vw, 3em); */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.about-h1 {
  text-align: left;
  font-size: clamp(3em, 12vw, 16em);
  margin: 0 0.8em;
}

.copy-container {
  padding-bottom: 6em;
  background-color: #f9cad6;
}

/* below this and above media queries is messy. come back to clean up */
.link {
  text-align: center;
  text-decoration: none;
  justify-content: center;
  padding: 0;
  color: #957E84;
}

.pikachu {
  width: 100%;
  padding: 10px;
  max-width: 400px;
  height: auto;
  align-items: center;
}

.avatar {
  width: 100%;
  padding: 10px;
  max-width: 400px;
  height: auto;
  align-items: center;
}

.card {
  text-align: center;
  justify-content: center;
  align-items: center;
  /* border: 1px black solid; */
  width: 100%;
  padding: 0;
}


.card-text {
  text-align: center;
  /* font-size: clamp(0.6em, 3vw, 1.2em); */
  font-size: clamp(.7rem, .6rem + 2vw, 1.2rem);
  /* font-size: 1.2rem; */
  padding: 0;
}

.container-fluid {
  margin: 0 auto;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.icon {
  font-size: 60px;
  margin: 15px;
  border-color: #FF6161;
  border: 2px solid;
}

.footer-icon {
  font-size: 5em;
  padding: 5px;
  margin: 10px;
  color: #000;
  border: 2px solid #000;
}

.home {
  max-width: 200rem;
}



/* .button {
  width: 6em;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font-size: clamp(.3rem, .2rem + 5vw, 1.2rem);
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.3s;
  font-weight: 700;
} */


.projects {
  font-size: 30px;
}


.col div {
  display: inline;
}

.contact {
  font-size: 30px;
  margin: 20px;
  padding: 20px;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .about-me p {
    font-size: 1em;
    text-align: left;
    margin: 1em 2.5em;
  }

  .global-container p {
    text-align: center;
    font-size: 1.6em;
    margin: 0.5em 1em;
  }

  .button {
    text-align: center;
    padding: 9em 0;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 1.4em;
    border-radius: 2.5em;
    border: 2px solid white;
    padding: 0.5em 0;
    width: 250px;
    font-weight: 700;
  }
}

@media screen and (min-width: 376px) and (max-width: 424px) {
  .about-me p {
    font-size: 1em;
    text-align: left;
    margin: 1em 2.5em;
  }

  .global-container p {
    text-align: center;
    font-size: 1.6em;
    margin: 0.5em 1em;
  }

  .button {
    text-align: center;
    padding: 9em 0;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 1.4em;
    border-radius: 2.5em;
    border: 2px solid white;
    padding: 0.5em 0;
    width: 250px;
    font-weight: 700;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {

  .sidebar {
    display: grid;
    overflow-x: hidden;
    max-width: 100%;
  }

  .about-me p {
    text-align: left;
    font-size: clamp(.4rem, .2rem + 5vw, 1rem);
    margin: 0 3em;
  }

  .global-container p {
    text-align: center;
    font-size: 1.6em;
    margin: 0.5em 1em;
  }

  .button {
    padding: 7em;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid white;
    padding: 0.5em 1em;
    font-weight: 700;
  }

  .global-container img {
    width: 100%;
  }
}


@media screen and (min-width: 551px) and (max-width: 650px) {
  .about-me p {
    text-align: left;
    font-size: 1em;
    margin: 0 3em;
  }

  .global-container p {
    text-align: center;
    font-size: 1.8em;
    margin: 0.5em 1em;
  }

  .button {
    padding: 7em;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid white;
    padding: 0.5em 1em;
    font-weight: 700;
  }

  .global-container img {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .icon {
    font-size: 70px;
    margin: 40px;
  }

  .button {
    padding: 8em;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 24px;
    border-radius: 30px;
    border: 2px solid white;
    padding: 0.5em 1em;
    font-weight: 700;
  }

  .global-container p {
    font-size: clamp(1.8em, 2vw, 3em);
    margin: 1em;
  }

  .global-container img {
    padding: 0 2em;
  }

  .column img {
    padding: 0 2em;
  }

  .footer-icon {
    font-size: 70px;
    margin: 20px;
  }

  .about-me p {
    text-align: left;
    font-size: 2em;
    margin: 0 4em;
  }

  /* .about-me p {
    text-align: left;
    height: 100%;
    font-size: 1em;
    margin: 0 5em;
  } */
}

@media screen and (min-width: 651px) and (max-width: 768px) {

  .about-me p {
    text-align: left;
    font-size: 1.2em;
    margin: 0 2em;
  }

  .global-container p {
    text-align: center;
    font-size: 1.8em;
    margin: 0.5em 1em;
  }

  .button {
    padding: 7em;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid white;
    padding: 0.5em 1em;
    font-weight: 700;
  }

  .global-container img {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .icon {
    font-size: 70px;
    margin: 40px;
  }

  .button {
    padding: 8em;
  }

  button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: white;
    background-color: black;
    font-size: 24px;
    border-radius: 30px;
    border: 2px solid white;
    padding: 0.5em 1em;
    font-weight: 700;
  }

  .global-container p {
    font-size: clamp(1.8em, 2vw, 3em);
    margin: 1em;
  }

  .global-container img {
    padding: 0 2em;
  }

  .footer-icon {
    font-size: 70px;
    margin: 20px;
  }

  .about-me p {
    text-align: left;
    font-size: 2.2em;
    margin: 0 6em;
  }
}