.dark {
    background-color: #000;
    color: #f49e9e;
    height: auto;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
}

.light {
    background-color: #E0EBFE;
    color: #333;
    height: auto;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
}

.light .theme-button {
    background-color: #E0EBFE;
    font-size: clamp(1em, 5px, 2em);
    color: black;
    padding: 0.3em;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
    border: none;
    width: 6em;
}

.dark .theme-button {
    background-color: black;
    font-size: clamp(1em, 5px, 2em);
    color: white;
    padding: 0.3em;
    cursor: pointer;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    border: none;
}

.light .contact-button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    cursor: pointer;
    color: black;
    background-color: white;
    font-size: 24px;
    border-radius: 7px;
    border: 2px solid black;
    padding: 0.5em 1em;
    font-weight: 700;
}

.dark .input {
    border: 2px solid #f49e9e;
}

.dark .contact-button {
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    /* width: 6em;
    border-radius: 5px; */
    cursor: pointer;
    color: black;
    font-size: 24px;
    background-color: white;
    border-radius: 7px;
    border: 2px solid #f49e9e;
    padding: 0.5em 1em;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* transition: all 0.3s; */
    /* margin-top: -4px; */
    font-weight: 700;
}

.light .nopage {
    padding-bottom: 7em;
}

.dark .nopage {
    padding-bottom: 7em;
}


@media screen and (min-width: 300px) and (max-width: 768px) {
    .light .card {
        height: 100%;
        padding: 3em;
    }

    .dark .card {
        height: 650px;
        padding: 1em;
    }

    .light .footer {
        height: 100%;
        padding: 1em 0 0.2em 0;
    }

    .dark .footer {
        height: 100%;
        padding: 1em 0 0.2em 0;
    }

    .light .nopage {
        padding-bottom: 20em;
    }
    
    .dark .nopage {
        padding-bottom: 20em;
    }
}


.light .card {
    /* background-color: white; */
    height: 30em;
    max-width: 100%;
    padding: 0 2em;
}

.dark .card {
    /* background-color: black; */
    height: 30em;
    max-width: 60%;
    padding: 0 2em;
}

.dark .sent {
    padding-bottom: 1.5em;
    overflow-y: hidden;
}

.light .sent {
    padding-bottom: 1.5em;
    overflow-y: hidden;
}

.light .sent-message {
    color: black;
    padding: 2em;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
}

.dark .sent-message {
    color: white;
    padding: 2em;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
}

/* #sent-message {
    font-size: 36px;
    color: black;
} */

.dark hr {
    margin: 4em 0;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    height: 8px;
}

.light hr {
    margin: 4em 0;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    background-color: black;
    height: 8px;
}

.dark .card-body {
    color: #f49e9e;
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    /* height: 400px; */
    padding: 0;
}

.light .card-body {
    /* height: 400px; */
    font-family: 'Century Gothic', 'AppleGothic', sans-serif;
    padding: 0;
}

.dark .footer-icon {
    color: #f49e9e;
    border: 1px solid #f49e9e;
}

.footer {
    height: 200px;
    padding: 1em 0 0.2em 0;
}